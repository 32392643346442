<template>
  <div :class="`bg-white d-flex flex-column ${isWide ? 'px-30 py-20' : 'p-10'}`">
    <span :class="`font-bold color-pink center ${isMobile ? 'font-15' : 'font-20'}`">EVENTBOX</span>
    <span :class="`font-bold center ${isMobile ? 'mt-3 font-25' : 'mt-5 font-40'}`">{{ $t('help_center') }}</span>
    <div :class="`d-flex ${isWide ? 'justify-content-between m-30 font-25' : isMobile ? 'm-5 flex-column font-15' : 'm-10 flex-column font-20'}`">
      <div class="m-5 vertical-center">
        <img :src="assets.partner" :width="60" :height="60" />
        <div :class="isMobile ? 'm-2' : 'm-5'">
          <p :class="`font-bold ${isMobile ? 'font-15' : 'font-20'}`">{{ $t('contact_partner') }}</p>
          <a href="mailto:info@eventboxde.com">
            <span :class="`${isMobile ? 'font-12' : 'font-15'}`">info@eventboxde.com</span>
          </a>
        </div>
      </div>
      <div class="m-5 vertical-center">
        <img :src="assets.inquiries" :width="60" :height="60" />
        <div :class="isMobile ? 'm-2' : 'm-5'">
          <p :class="`font-bold ${isMobile ? 'font-15' : 'font-20'}`">{{ $t('contact_inquiries') }}</p>
          <a href="mailto:info@eventboxde.com">
            <span :class="`${isMobile ? 'font-12' : 'font-15'}`">info@eventboxde.com</span>
          </a>
        </div>
      </div>
      <div class="m-5 vertical-center">
        <img :src="assets.privacy" :width="60" :height="60" />
        <div :class="isMobile ? 'm-2' : 'm-5'">
          <p :class="`font-bold ${isMobile ? 'font-15' : 'font-20'}`">{{ $t('contact_privacy') }}</p>
          <a href="mailto:info@eventboxde.com">
            <span :class="`${isMobile ? 'font-12' : 'font-15'}`">info@eventboxde.com</span>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import partner from '@/assets/images/partner.png';
import inquiries from '@/assets/images/inquiries.png';
import privacy from '@/assets/images/privacy.png';

export default {
  name: 'Contact',
  computed: {
    isMobile() {
      return this.$store.state.isMobile;
    },
    isWide() {
      return this.$store.state.isWide;
    }
  },
  data() {
    return {
      assets: {
        partner,
        inquiries,
        privacy
      }
    };
  }
};
</script>
